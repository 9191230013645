/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Section from "src/components/Section"
// import ContainerHero from "src/components/ContainerHero"
import Container from "src/components/Container"

import { HeroWrap } from "src/components/HeroComponents"

const Hero = (props) => {
  const { image, title, isRTL, buttonLink } = props
  const gatsbyImageData = getImage(image?.sourceLocal)

  return (
    <Section
      className="hero-container"
      sx={{
        variant: "spacing.heroHome",
        maxWidth: 1440,
        mx: "auto",
        position: "relative",
        "&:after": {
          content: "''",
          position: "absolute",
          zIndex: -1,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      }}
    >
      {gatsbyImageData && (
        <GatsbyImage
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
          sx={{
            "&:after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                "linear-gradient(to right, hsl(0 0% 0% / 0.9) 35%, hsl(0 0% 0% / 0.4) 100%)",
            },
          }}
          objectFit="cover"
          objectPosition="50% 50%"
          image={gatsbyImageData}
          alt={image.altText ?? ""}
        />
      )}
      <Container
        sx={{
          minHeight: [192, 220, 280, 360, 470],
          display: "flex",
          alignItems: "center",
        }}
      >
        <HeroWrap
          isRTL={isRTL}
          sx={{
            py: [5, 5, 6, 7],
          }}
          buttonLink={{ ...buttonLink, theme: "whiteAlt" }}
        >
          {title && (
            <Themed.h1
              sx={{
                maxWidth: [200, 240, 300, 420, 500],
                color: "white",
                mt: "4",
                mb: "5",
              }}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}
        </HeroWrap>
      </Container>
    </Section>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  isRTL: PropTypes.bool,
  image: PropTypes.shape({
    sourceLocal: PropTypes.shape({
      childImageSharp: PropTypes.object.isRequired,
    }).isRequired,
  }),
}

Hero.defaultProps = {
  isRTL: false,
}

export default Hero
